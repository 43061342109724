import GetLogger from '@components/Logger';
import Addresses from '@constants/addresses';
import { DEV_TEST_ADDRESS, MIN_CACHE_TIME_MS, OLD_CHAIN } from '@constants/variables';
import { useAppDispatch, useAppSelector } from '@state/hooks';
import { selectProviderNetworkOld } from '@state/provider/hooks';
import { selectWalletRefetch, selectWalletAddress, selectWalletBalances } from '@state/wallet/hooks';
import { newBalance, setWalletAddress, setWalletBalances } from '@state/wallet/reducer';
import { GetERC20Contract, GetERC721Contract, GetLPTokenContract } from '@utils/contracts';

import { ContractCall } from 'ethers-multicall';
import { useEffect } from 'react';
import { useAccount } from "wagmi";

const TokenUpdater = () : null => {
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address
  const log = GetLogger("Wallet Updater");
  const dispatch = useAppDispatch();
  const provider = useAppSelector(selectProviderNetworkOld);
  const refetch = useAppSelector(selectWalletRefetch);
  const balances = useAppSelector(selectWalletBalances);
  const savedAddress = useAppSelector(selectWalletAddress)

  const magic = GetERC20Contract(Addresses.Tokens.MAGIC[OLD_CHAIN]);

  useEffect(() => {
    if (!wallet) {
      return;
    }
    if (savedAddress !== wallet) {
      dispatch(setWalletAddress(wallet))
    }
  }, [wallet, savedAddress]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!wallet) {
      return;
    }
    const getBalances = async () => {
      const now = Date.now();
      const newBalances = {};
      const newRefetch = {};
      const tokenEntries = Object.entries(Addresses.Tokens).filter(([token, address]) =>
        (now - refetch[token]) > MIN_CACHE_TIME_MS
      )

      if (tokenEntries.length > 0) {
        let tokenBalanceCalls: ContractCall[] = [];
        tokenEntries.map(async ([token, address]) => {
          const contract = GetERC20Contract(address[OLD_CHAIN]);
          const isMAGIC = token === 'MAGIC'
          const isXMAGIC = token === 'xMAGIC'
          const calls = [
            contract.balanceOf(wallet),
            isMAGIC ? contract.lockOf(wallet) : (isXMAGIC ? magic : contract).balanceOf(contract.address),
            isMAGIC ? contract.canUnlockAmount(wallet) : contract.totalSupply(),
            isMAGIC ? contract.totalBalanceOf(wallet) : contract.totalSupply()
          ]
          tokenBalanceCalls = tokenBalanceCalls.concat(calls)

        })
        log.debug("Token balance call length", tokenBalanceCalls.length)
        try {
          const tokenBalances = await provider.all(tokenBalanceCalls);
          tokenEntries.map(async ([token, address], i) => {
            const baseIndex = i * 4
            newBalances[token] = {
              balance: tokenBalances[baseIndex],
              locked: tokenBalances[baseIndex + 1],
              unlocked: tokenBalances[baseIndex + 2],
              total: tokenBalances[baseIndex + 3]
            }
            newRefetch[token] = now + MIN_CACHE_TIME_MS;
          })
        } catch (e) {
          log.error(`Error querying token data`, e)
        }
      }

      const lpTokenEntries = Object.entries(Addresses.LPTokens).filter(([token, address]) =>
        (now - refetch[token]) > MIN_CACHE_TIME_MS
      )

      if (lpTokenEntries.length > 0) {
        const lpTokenBalanceCalls: ContractCall[] = [];

        lpTokenEntries.map(async ([token, address]) => {
          const contract = GetLPTokenContract(address[OLD_CHAIN]);
          lpTokenBalanceCalls.push(contract.balanceOf(wallet))
        })
        log.debug("Lp Token balance call length", lpTokenBalanceCalls.length)
        try {
          const lpTokenBalances = await provider.all(lpTokenBalanceCalls);
          lpTokenEntries.map(async ([token, address], i) => {
            newBalances[token] = newBalance();
            newBalances[token].balance = lpTokenBalances[i];
            newRefetch[token] = now + MIN_CACHE_TIME_MS;
            return null;
          })
        } catch (e) {
          log.error(`Error querying token data`, e)
        }
      }

      const nftEntries = Object.entries(Addresses.NFTs).filter(([token, address]) =>
        (now - refetch[token]) > MIN_CACHE_TIME_MS
      )
      if (nftEntries.length > 0) {
        const nftCountCalls: ContractCall[] = [];

        nftEntries.map(([, address]) => {
          const contract = GetERC721Contract(address[OLD_CHAIN])
          nftCountCalls.push(contract.balanceOf(wallet))
          return null;
        })
        log.debug("NFT balance call length", nftCountCalls.length)


        try {
          const nftCounts = await provider.all(nftCountCalls);
          nftEntries.map(([token, address], i) => {
            newBalances[token] = newBalance();
            newBalances[token].balance = nftCounts[i];
            newRefetch[token] = now + MIN_CACHE_TIME_MS;
            return null;
          })
        } catch (e) {
          log.error("Error querying NFT Balance data", e)
          return;
        }
      }
      if (Object.keys(newBalances).length > 0) {
        dispatch(setWalletBalances({ balances: newBalances, refetch: newRefetch }))
      }
    }
    getBalances();

    const interval = setInterval(() => getBalances(), 60000);
    return () => {
      clearInterval(interval);
    }
  }, [wallet, refetch, balances]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default TokenUpdater;
