import { Box, keyframes, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import { selectAppLoading } from '@state/app/hooks';
import { useAppSelector } from '@state/hooks';
import ForestHarnesser from '@assets/images/Forest_Harnesser.png';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const LoadingBackdrop = () => {
  const open = useAppSelector(selectAppLoading)
  return (
    <Backdrop open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
      <Stack>
        <Box sx={{ animation: `${spin} 2s infinite linear` }}>
          <img src={ForestHarnesser} alt="Staff" />
        </Box>
        <Typography variant="h4" sx={{ textAlign: 'center', color: 'white' }}>Loading...</Typography>
      </Stack>
    </Backdrop>
  )
}

export default LoadingBackdrop;
