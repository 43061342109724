import { client } from "@constants/chains";
import { ThemeProvider } from '@mui/material';
import store from '@state/store';
import WalletUpdater from '@state/wallet/updaters';
import MarketUpdater from '@state/market/updater';
import { ConnectKitProvider } from 'connectkit';
import React, { Fragment, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '@pages/App';
import { Provider } from 'react-redux';
import theme from '@theme/base';
import { WagmiConfig } from "wagmi";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const Updaters = () => {
  return (
    <Fragment>
      <MarketUpdater />
      <WalletUpdater />
    </Fragment>
  )
}

root.render(
  <StrictMode>
    <WagmiConfig client={client}>
      <ConnectKitProvider mode="dark" options={{ walletConnectName: "WalletConnect" }}>
        <Provider store={store}>
          <Updaters />
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </ConnectKitProvider>
    </WagmiConfig>
  </StrictMode>
);

