import { ZERO_ADDRESS } from '@constants/variables';

export enum SaleType {
  AUCTION,
  FIXED,
  OFFER
}

export const saleTypeToString = (saleType: SaleType) => {
  switch (saleType) {
    case SaleType.FIXED:
      return "Sale";
    case SaleType.AUCTION:
      return "Auction";
    case SaleType.OFFER:
      return "Offer";
    default:
      return "Unknown";
  }
}

export enum TokenType {
  ERC721,
  ERC1155
}

export type Sale = {
  saleId: number;
  saleType: SaleType;
  seller: string;
  contractAddress: string;
  tokenType: TokenType;
  bidToken: string;
  startTime: number;
  duration: number;
  extensionDuration: number;
  endTime: number;
  bidder: string;
  bidAmount: string;
}

export const saleToAction = (sale: Sale) => {
  const ended = Math.floor(Date.now() / 1000) > sale.endTime;
  if (SaleType.AUCTION) {
    if (ended && sale.bidder !== ZERO_ADDRESS) {
      return 'Finalize';
    }
    return 'None';
  }
  if (SaleType.FIXED && ![sale.bidder, sale.seller].includes(ZERO_ADDRESS)) {
    return 'Finalize';
  }
  return 'Cancel';
}

export interface MarketState {
  sales: Sale[];
}
