import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#c4aa63"
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          background: 'linear-gradient(227deg, rgb(2, 0, 36) 0%, rgb(20, 0, 52) 40%)',
          borderRadius: '16px'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          paddingBottom: 0
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          marginTop: 'auto'
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          justifyContent: 'space-around',
          marginTop: 0,
          '&.MuiGrid-item': {
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'stretch',
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(11, 180, 231)',
          borderRadius: '16px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          textAlign: 'center'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'black'
        }
      }
    }
  }
});

export default theme;