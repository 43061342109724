import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from 'react';

const Concluded = () => {
  const lastDay = new Date(1672527599000)
  const daysAgo = Math.floor((Date.now() - 1672527599000) / 1000 / 60 / 60 / 24)
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Stack spacing={2} sx={{ marginTop: 2, textAlign: 'center' }} maxWidth="sm">
        <Typography variant="h3" color="#c4aa63">Cosmic Universe's Migration Has Concluded</Typography>
        <Typography variant="h6" color="#c4aa63">The migration started {(new Date(1656633600000)).toString()} and lasted 6 months</Typography>
        <Typography variant="h6" color="#c4aa63">The last day of migration was {lastDay.toString()} ({daysAgo} Days ago)</Typography>

        <Typography variant="h5" color="#c4aa63">
          All NFT assets that were not bridged in a timely fashion have been minted for their respective
          owners when the bridge closed, with the project bearing the cost of the remaining mints, in good faith
        </Typography>

        <Typography variant="h5" color="#c4aa63">
          MAGIC, COSMIC, and COSMICV2 Tokens were announced at the beginning of the migration to have zero value on the
          Harmony blockchain, but would bridge 1:1 if bridged during the migration period.
        </Typography>

        <Typography variant="h5" color="#c4aa63">
          While we understand that it may seem unfortunate and/or frustrating to those of you who missed the bridging
          period, we too find it unfortunate that there was not 90 seconds in the last 6 months that you were willing to
          spare for an exciting and quickly evolving project.
        </Typography>
      </Stack>
    </Box>

  )
}

export default Concluded;
