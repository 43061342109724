import WalletButton from '@components/WalletButton';
import React from 'react';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const TopBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#c4aa63" }}>
          Cosmic Universe Migration Tool
        </Typography>
        {/* <WalletButton /> */}
      </Toolbar>
    </AppBar>
  )
}

export default TopBar;