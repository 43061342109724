import Addresses from '@constants/addresses';
import { ZERO_ADDRESS } from '@constants/variables';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AllNftBalancesPayload,
  AllWalletBalancesPayload,
  NftBalancePayload,
  SetWalletBalancePayload,
  WalletBalance,
  WalletBalances,
  WalletState,
} from '@state/wallet/types';

import { BigNumber } from 'ethers';

export const newBalance = (): WalletBalance => ({
  balance: BigNumber.from(0),
  unlocked: BigNumber.from(0),
  locked: BigNumber.from(0),
  total: BigNumber.from(0)
})

const allTokens = Object.keys(Addresses.Tokens)
  .concat(Object.keys(Addresses.LPTokens))
  .concat(Object.keys(Addresses.VestingTokens))
  .concat(Object.keys(Addresses.NFTs))

const initialState: WalletState = {
  address: "",
  refetch: allTokens.reduce((o, t) => {
    o[t] = 0;
    return o;
  }, {}),
  balances: allTokens.reduce((o, t) => {
    o[t] = newBalance();
    return o;
  }, {} as WalletBalances),
  nfts: Object.keys(Addresses.NFTs).reduce((o, t) => {
    o[t] = [];
    return o;
  }, {}),
  approvals: allTokens.reduce((o, t) => {
    o[t] = false;
    return o;
  }, {}),
  pools: Object.keys(Addresses.LPTokens).reduce((o, t) => {
    o[t] = BigNumber.from(0);
    return o;
  }, {}),
  staked: [],
  escrow: ZERO_ADDRESS
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletBalances: (state, action: PayloadAction<AllWalletBalancesPayload>) => {
      state.balances = { ...state.balances, ...action.payload.balances };
      state.refetch = { ...state.refetch, ...action.payload.refetch };
    },
    setWalletBalance: (state, action: PayloadAction<SetWalletBalancePayload>) => {
      state.balances[action.payload.token] = action.payload.balance;
    },
    setWalletRefetchBalance: (state, action: PayloadAction<string>) => {
      state.refetch[action.payload] = 0;
    },
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setWalletAllApprovals: (state, action: PayloadAction<{ [token: string]: boolean }>) => {
      state.approvals = { ...state.approvals, ...action.payload };
    },
    setWalletApproval: (state, action: PayloadAction<{ token: string, approved: boolean }>) => {
      state.approvals[action.payload.token] = action.payload.approved;
    },
    setWalletEscrow: (state, action: PayloadAction<string>) => {
      state.escrow = action.payload;
    },
    setWalletNftBalances: (state, action: PayloadAction<AllNftBalancesPayload>) => {
      state.nfts = { ...state.nfts, ...action.payload.nfts };
      state.refetch = { ...state.refetch, ...action.payload.refetch };
    },
    setWalletNftBalance: (state, action: PayloadAction<NftBalancePayload>) => {
      state.nfts[action.payload.token] = action.payload.nfts;
      state.refetch[action.payload.token] = action.payload.refetch;
    },
    setWalletPools: (state, action: PayloadAction<{ [token: string]: BigNumber }>) => {
      state.pools = { ...state.pools, ...action.payload }
    },
    setWalletStaked: (state, action: PayloadAction<number[]>) => {
      state.staked = action.payload
    },
  }
})

export const {
  setWalletBalances,
  setWalletBalance,
  setWalletRefetchBalance,
  setWalletAddress,
  setWalletAllApprovals,
  setWalletApproval,
  setWalletEscrow,
  setWalletNftBalance,
  setWalletNftBalances,
  setWalletPools,
  setWalletStaked
} = walletSlice.actions;

export default walletSlice.reducer;