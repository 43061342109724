import GetLogger from '@components/Logger';
import { DEV_TEST_ADDRESS, ZERO_ADDRESS } from '@constants/variables';
import { useAppDispatch, useAppSelector } from '@state/hooks';
import { setMarketSales } from '@state/market/reducer';
import { Sale } from '@state/market/types';
import { selectProviderNetworkOld } from '@state/provider/hooks';
import { ContractCall } from 'ethers-multicall';
import { useAccount } from "wagmi";
import sales from './sales';
import { GetMarketContract } from '@utils/contracts';
import { FC, useEffect } from 'react';

const MarketUpdater: FC = (): null => {
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address
  const log = GetLogger("Market Updater");
  const provider = useAppSelector(selectProviderNetworkOld);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!wallet) {
      return;
    }

    const get = async () => {
      const fSales = sales.filter(s => [s.seller, s.bidder].includes(wallet))
      const contractCalls: ContractCall[] = []
      for (const sale of fSales) {
        contractCalls.push(GetMarketContract().saleIdToSale(sale.saleId))
      }
      try {
        const valid: Sale[] = [];
        const potentialSales = await provider.all(contractCalls)
        potentialSales.map((s, i) => {
          if (s.contractAddress !== ZERO_ADDRESS) {
            valid.push(fSales[i])
          }
          return s;
        })
        if (valid.length > 0) {
          dispatch(setMarketSales(valid))
        }
      } catch (e) {
        log.debug(e)
      }
    }
    get();
    const interval = setInterval(() => {
      get();
    }, 60000);

    return () => {
      clearInterval(interval);
    }
  }, [wallet]); // eslint-disable-line react-hooks/exhaustive-deps
  return null
}

export default MarketUpdater;
