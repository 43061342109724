import { RootState } from '@state/store';

export const selectWalletBalances = (state: RootState) => state.wallet.balances
export const selectWalletRefetch = (state: RootState) => state.wallet.refetch
export const selectWalletAddress = (state: RootState) => state.wallet.address
export const selectWalletNfts = (state: RootState) => state.wallet.nfts
export const selectWalletApprovals = (state: RootState) => state.wallet.approvals
export const selectWalletEscrow = (state: RootState) => state.wallet.escrow
export const selectWalletPools = (state: RootState) => state.wallet.pools
export const selectWalletStaked = (state: RootState) => state.wallet.staked
