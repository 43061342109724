import { Sale } from '@state/market/types';

const sales: Sale[] = [
  {
    saleId: 112,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646212499,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1646298899,
    bidder: '0x6c9a82A231ef073F98ade6105F6888073A8930D0',
    bidAmount: '4555000000000000000'
  },
  {
    saleId: 163,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646218251,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1646304651,
    bidder: '0xF68a18EeA7fA2Ea147C2dAFb55a8d2b9B19B7d17',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 170,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646218742,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1646305142,
    bidder: '0xF68a18EeA7fA2Ea147C2dAFb55a8d2b9B19B7d17',
    bidAmount: '600000000000000000000'
  },
  {
    saleId: 241,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646223635,
    duration: 432000,
    extensionDuration: 900,
    endTime: 1646655635,
    bidder: '0x120f5C9cEE73bC3283a506005EBE966a709D7Fb9',
    bidAmount: '100000000000000000000'
  },
  {
    saleId: 396,
    saleType: 1,
    seller: '0xA0Fb85CF5B0d9966468F9D9BE0A824565b310db6',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646242283,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1646415083,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2000000000000000000000'
  },
  {
    saleId: 445,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646250469,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1646423269,
    bidder: '0x9a8b8Ab05F061D195338c17D02C93678885646Ad',
    bidAmount: '1200000000000000000000'
  },
  {
    saleId: 491,
    saleType: 1,
    seller: '0x4bbB94F56b5F138ad759Da8E0e6f8102843F24A8',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646262853,
    duration: 3888000,
    extensionDuration: 900,
    endTime: 1650150853,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '999000000000000000000'
  },
  {
    saleId: 494,
    saleType: 1,
    seller: '0x4bbB94F56b5F138ad759Da8E0e6f8102843F24A8',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646263045,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1648855045,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1999000000000000000000'
  },
  {
    saleId: 528,
    saleType: 1,
    seller: '0x480aFd5737eEC69FD8aAdcC465942A93b236A5d7',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646272948,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1646877748,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '285000000000000000000'
  },
  {
    saleId: 530,
    saleType: 1,
    seller: '0x480aFd5737eEC69FD8aAdcC465942A93b236A5d7',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646273118,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1648865118,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '5000000000000000000000'
  },
  {
    saleId: 531,
    saleType: 1,
    seller: '0x480aFd5737eEC69FD8aAdcC465942A93b236A5d7',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646273204,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1646878004,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 549,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646280218,
    duration: 864000,
    extensionDuration: 900,
    endTime: 1647144218,
    bidder: '0x1a6d5fADf58919Cbd35d9Acd32C09E73Bc82EbC7',
    bidAmount: '1000000000000000000'
  },
  {
    saleId: 553,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646281117,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1646453917,
    bidder: '0xBD2a10a18De5b1416650E3F15467aaF6b0CE424a',
    bidAmount: '1500000000000000000'
  },
  {
    saleId: 556,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646282457,
    duration: 864000,
    extensionDuration: 900,
    endTime: 1647146457,
    bidder: '0xe0EbD9f22027730dAe8E6ad5f649564bB1684aca',
    bidAmount: '5000000000000000000'
  },
  {
    saleId: 783,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646382534,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1646555334,
    bidder: '0x3E1b809d896876eb5B44A452F9b9F065EBA768E3',
    bidAmount: '1500000000000000000000'
  },
  {
    saleId: 784,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646382651,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1646555451,
    bidder: '0x3E1b809d896876eb5B44A452F9b9F065EBA768E3',
    bidAmount: '1500000000000000000000'
  },
  {
    saleId: 870,
    saleType: 1,
    seller: '0x4bbB94F56b5F138ad759Da8E0e6f8102843F24A8',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646438359,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1649030359,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2950000000000000000000'
  },
  {
    saleId: 905,
    saleType: 1,
    seller: '0xf8FaB446e40CA05E87d9C32B089B093616dbA71D',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646474172,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1649066172,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '5000000000000000000000'
  },
  {
    saleId: 1070,
    saleType: 1,
    seller: '0x480aFd5737eEC69FD8aAdcC465942A93b236A5d7',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646633961,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1647238761,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1111000000000000000000'
  },
  {
    saleId: 1307,
    saleType: 1,
    seller: '0x93251CD524c4F21358A09A8B2F2271DE86D7A4c5',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1646873124,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1646959524,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2000000000000000000000'
  },
  {
    saleId: 1397,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647018299,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1647277499,
    bidder: '0xE9463b1d8c1A461a48d675D032aa5768A47cd267',
    bidAmount: '1240000000000000000000'
  },
  {
    saleId: 1398,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647018434,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1647277634,
    bidder: '0xE9463b1d8c1A461a48d675D032aa5768A47cd267',
    bidAmount: '1240000000000000000000'
  },
  {
    saleId: 1564,
    saleType: 1,
    seller: '0xe7D1C2A29a6c1D1fa460ba4c26f06B97b76eE931',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647238032,
    duration: 864000,
    extensionDuration: 900,
    endTime: 1648102032,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 1705,
    saleType: 1,
    seller: '0xaCc261f95c552386Da8350a6FffE3A0b9Ca67E0B',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647354123,
    duration: 864000,
    extensionDuration: 900,
    endTime: 1648218123,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 1980,
    saleType: 1,
    seller: '0xd81B80266B9015d21B8A70d260833eC995a9884c',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647537393,
    duration: 432000,
    extensionDuration: 900,
    endTime: 1647969393,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1000000000000000000000'
  },
  {
    saleId: 2179,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647738212,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1647997412,
    bidder: '0x42Ff4A65b5fd4eD279d3E5849298D97A291d5432',
    bidAmount: '888000000000000000000'
  },
  {
    saleId: 2360,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647960706,
    duration: 432000,
    extensionDuration: 900,
    endTime: 1648392706,
    bidder: '0xb13B9E1C8C15F789795b4a3cdfbb172e37673208',
    bidAmount: '3000000000000000000000'
  },
  {
    saleId: 2424,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647994970,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1648599770,
    bidder: '0x654A49DB078FFD34221f2C1E3812A4201cD11378',
    bidAmount: '1000000000000000000000'
  },
  {
    saleId: 2425,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1647995051,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1648599851,
    bidder: '0x654A49DB078FFD34221f2C1E3812A4201cD11378',
    bidAmount: '1000000000000000000000'
  },
  {
    saleId: 2985,
    saleType: 0,
    seller: '0x7d91726d37fa9caf6baFDF4a03F3C55642e866b2',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648751621,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1648838021,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3500000000000000000000'
  },
  {
    saleId: 2986,
    saleType: 0,
    seller: '0x7d91726d37fa9caf6baFDF4a03F3C55642e866b2',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648752128,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1648838528,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3500000000000000000000'
  },
  {
    saleId: 2987,
    saleType: 0,
    seller: '0x7d91726d37fa9caf6baFDF4a03F3C55642e866b2',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648752259,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1648838659,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3500000000000000000000'
  },
  {
    saleId: 2989,
    saleType: 0,
    seller: '0x7d91726d37fa9caf6baFDF4a03F3C55642e866b2',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648752559,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1648838959,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3500000000000000000000'
  },
  {
    saleId: 3053,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648818636,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1648905036,
    bidder: '0x3F9023DC53798fFbd321B296E9480729FB4d870A',
    bidAmount: '2480000000000000000000'
  },
  {
    saleId: 3059,
    saleType: 1,
    seller: '0x5A84A383BBCdce1Ddf04d3b482C5B0DD64Dc3006',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648821064,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1649080264,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3225000000000000000000'
  },
  {
    saleId: 3147,
    saleType: 1,
    seller: '0xa87D98510dc098c7363E72994b1062412ACE3F03',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648987029,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1651579029,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2000000000000000000000'
  },
  {
    saleId: 3148,
    saleType: 1,
    seller: '0xa87D98510dc098c7363E72994b1062412ACE3F03',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1648987095,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1651579095,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '30000000000000000000000'
  },
  {
    saleId: 3407,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1649443597,
    duration: 5184000,
    extensionDuration: 900,
    endTime: 1654627597,
    bidder: '0x574e592FfEFd8E8Bf862e2a2ee9A4Bd763251650',
    bidAmount: '800000000000000000000'
  },
  {
    saleId: 3428,
    saleType: 1,
    seller: '0x807fB3Dca069266C6AfD5BcDc35d821d3D0cA16e',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1649516489,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1652108489,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2998000000000000000000'
  },
  {
    saleId: 3553,
    saleType: 1,
    seller: '0x9d5A91d64d88555Bc4b931862ffD7831A1079a05',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1649780422,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1650385222,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2000000000000000000000'
  },
  {
    saleId: 3554,
    saleType: 1,
    seller: '0x9d5A91d64d88555Bc4b931862ffD7831A1079a05',
    contractAddress: '0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1649780491,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1650385291,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2000000000000000000000'
  },
  {
    saleId: 3619,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1649937300,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1650542100,
    bidder: '0xb13B9E1C8C15F789795b4a3cdfbb172e37673208',
    bidAmount: '3000000000000000000000'
  },
  {
    saleId: 3687,
    saleType: 1,
    seller: '0xb59965147dA87119cBDCFDee1d04304381cC6Ec5',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650144294,
    duration: 1728000,
    extensionDuration: 900,
    endTime: 1651872294,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9000000000000000000000'
  },
  {
    saleId: 3688,
    saleType: 1,
    seller: '0xb59965147dA87119cBDCFDee1d04304381cC6Ec5',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650144404,
    duration: 1728000,
    extensionDuration: 900,
    endTime: 1651872404,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9000000000000000000000'
  },
  {
    saleId: 3689,
    saleType: 1,
    seller: '0xb59965147dA87119cBDCFDee1d04304381cC6Ec5',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650144504,
    duration: 1728000,
    extensionDuration: 900,
    endTime: 1651872504,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9000000000000000000000'
  },
  {
    saleId: 3744,
    saleType: 1,
    seller: '0xcA956196FD1772BE1D6E6a631E9Ca9cd2182D829',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650287931,
    duration: 1209600,
    extensionDuration: 900,
    endTime: 1651497531,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 3786,
    saleType: 1,
    seller: '0x6dAc62E20F3C62819e98148BD346E44506778024',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650312214,
    duration: 1209600,
    extensionDuration: 900,
    endTime: 1651521814,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 3787,
    saleType: 1,
    seller: '0x6dAc62E20F3C62819e98148BD346E44506778024',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650312310,
    duration: 1209600,
    extensionDuration: 900,
    endTime: 1651521910,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 3853,
    saleType: 1,
    seller: '0x6a5B0A0c161A825f3064946184A3EF08561a7453',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650383697,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1652975697,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8149000000000000000000'
  },
  {
    saleId: 3920,
    saleType: 1,
    seller: '0x7bdB3b90F8870A78D757959bbf7f7F0d2aF94a5A',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650519388,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1651124188,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '690000000000000000000'
  },
  {
    saleId: 4023,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650811738,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1650984538,
    bidder: '0x270e751a3fda566E96Ce362190B58298AD279e62',
    bidAmount: '505000000000000000000'
  },
  {
    saleId: 4140,
    saleType: 1,
    seller: '0xd6eD364cc14e9e3bAE13b2df9FAd515d6d277f79',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650986861,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1653578861,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '42690000000000000000000'
  },
  {
    saleId: 4141,
    saleType: 1,
    seller: '0xd6eD364cc14e9e3bAE13b2df9FAd515d6d277f79',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650986965,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1653578965,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '24900000000000000000000'
  },
  {
    saleId: 4142,
    saleType: 1,
    seller: '0xd6eD364cc14e9e3bAE13b2df9FAd515d6d277f79',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1650987029,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1653579029,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '29000000000000000000000'
  },
  {
    saleId: 4391,
    saleType: 0,
    seller: '0xC603180e4b43b66759511eC1fdafc22BFF009132',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1651581338,
    duration: 7776000,
    extensionDuration: 900,
    endTime: 1659357338,
    bidder: '0xdFf5A312DeDA1d7efD1DDcFf3F79755CbAea8253',
    bidAmount: '3328000000000000000000'
  },
  {
    saleId: 4458,
    saleType: 1,
    seller: '0x42654365Dd6A5924A1982985918138089818B41E',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1651658240,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1652263040,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '850000000000000000000'
  },
  {
    saleId: 4694,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652049614,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1654641614,
    bidder: '0x7276F8b3a8EB15679F572973F5296b8cC4c08Fc5',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 4695,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652049701,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1654641701,
    bidder: '0x7276F8b3a8EB15679F572973F5296b8cC4c08Fc5',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 4696,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652049775,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1654641775,
    bidder: '0x7276F8b3a8EB15679F572973F5296b8cC4c08Fc5',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 4698,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652056667,
    duration: 172800,
    extensionDuration: 900,
    endTime: 1652229467,
    bidder: '0x09C76E1F683EfBC6E3aa552CC42f9EaAaebEeC74',
    bidAmount: '888000000000000000000'
  },
  {
    saleId: 4731,
    saleType: 1,
    seller: '0xeF4E8204Ebc6FCfE661D71883A83295f9D7e46bE',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652095344,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1654687344,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9999000000000000000000'
  },
  {
    saleId: 4821,
    saleType: 1,
    seller: '0x8B04b3f4c37Ac285E2B714D30963967609525E37',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652187262,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1654779262,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3150000000000000000000'
  },
  {
    saleId: 5009,
    saleType: 1,
    seller: '0x092E4cf1C4De7c2C78e58EDD964F1dd9DAECF3c6',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652555963,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1655147963,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1200000000000000000000'
  },
  {
    saleId: 5025,
    saleType: 1,
    seller: '0x3dec42C1644f614b8C6f16C78C04a08207826e37',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652579307,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1655171307,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4000000000000000000000'
  },
  {
    saleId: 5038,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652600944,
    duration: 432000,
    extensionDuration: 900,
    endTime: 1653032944,
    bidder: '0x2884885673769543B0bB4BbB11959B01E504Ab4C',
    bidAmount: '1000000000000000000000'
  },
  {
    saleId: 5042,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652614847,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653219647,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 5043,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652614959,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653219759,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4000000000000000000000'
  },
  {
    saleId: 5044,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652615081,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653219881,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3000000000000000000000'
  },
  {
    saleId: 5045,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652615209,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653220009,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '350000000000000000000'
  },
  {
    saleId: 5047,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652615275,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653220075,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '400000000000000000000'
  },
  {
    saleId: 5048,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652615353,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653220153,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '450000000000000000000'
  },
  {
    saleId: 5049,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652615439,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653220239,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '600000000000000000000'
  },
  {
    saleId: 5051,
    saleType: 1,
    seller: '0x665D047FA597b4f447A4Fb9887eAEbA4189dEde1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652615500,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653220300,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '420000000000000000000'
  },
  {
    saleId: 5064,
    saleType: 1,
    seller: '0x3643a16eb6a10d951300c10909e914F957cD438a',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652653974,
    duration: 25920000,
    extensionDuration: 900,
    endTime: 1678573974,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1100000000000000000000'
  },
  {
    saleId: 5089,
    saleType: 1,
    seller: '0xf48443c8e441A29Ac3aD8560E6C46bcF0c82cFD1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652671850,
    duration: 432000,
    extensionDuration: 900,
    endTime: 1653103850,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 5090,
    saleType: 1,
    seller: '0xf48443c8e441A29Ac3aD8560E6C46bcF0c82cFD1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652671932,
    duration: 432000,
    extensionDuration: 900,
    endTime: 1653103932,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '850000000000000000000'
  },
  {
    saleId: 5091,
    saleType: 1,
    seller: '0xf48443c8e441A29Ac3aD8560E6C46bcF0c82cFD1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652672008,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653276808,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 5092,
    saleType: 1,
    seller: '0xf48443c8e441A29Ac3aD8560E6C46bcF0c82cFD1',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652672072,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653276872,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '450000000000000000000'
  },
  {
    saleId: 5213,
    saleType: 1,
    seller: '0xEB37c1E7A7A83886876F659bc558c8784386F28a',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652989496,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653594296,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1899000000000000000000'
  },
  {
    saleId: 5215,
    saleType: 1,
    seller: '0xEB37c1E7A7A83886876F659bc558c8784386F28a',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1652989812,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653594612,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1999000000000000000000'
  },
  {
    saleId: 5257,
    saleType: 1,
    seller: '0x7B3ae92Ec93d4801ec01FCa81AF2A81c3c8A3607',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653136725,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653741525,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '149000000000000000000'
  },
  {
    saleId: 5284,
    saleType: 1,
    seller: '0x532f9cbFD288056445c77c05c7B02eF1a9697363',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653215356,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1655807356,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2000000000000000000000'
  },
  {
    saleId: 5292,
    saleType: 1,
    seller: '0x8Abfb033160D91f0c937a9e1D7B91F7E5a8A44D1',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653234476,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653839276,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2500000000000000000000'
  },
  {
    saleId: 5306,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653263759,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1653522959,
    bidder: '0x8d0c98b753DA130A0Fa5AAB2275Eebdf4dD10f54',
    bidAmount: '2850000000000000000000'
  },
  {
    saleId: 5309,
    saleType: 1,
    seller: '0x5624937f595d3b1B6565d0405584452532cb9962',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653265340,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653870140,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '400000000000000000000'
  },
  {
    saleId: 5315,
    saleType: 1,
    seller: '0x3A0D8daa2d467c81D410a8EE8Bd3a445674664b7',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653277740,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1653882540,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1399000000000000000000'
  },
  {
    saleId: 5340,
    saleType: 1,
    seller: '0x1865C3D08a38a35554Bc78904AD971d9A5C86cA3',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653353039,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1653439439,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '50000000000000000000000'
  },
  {
    saleId: 5395,
    saleType: 1,
    seller: '0x7A5F6A8f97f8aF495F040c54f3cc75f7bc6bc59c',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653399118,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1654003918,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2899000000000000000000'
  },
  {
    saleId: 5408,
    saleType: 1,
    seller: '0x1865C3D08a38a35554Bc78904AD971d9A5C86cA3',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653439941,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1653526341,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '40000000000000000000000'
  },
  {
    saleId: 5460,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558170,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150170,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '7500000000000000000000'
  },
  {
    saleId: 5461,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558300,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150300,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8000000000000000000000'
  },
  {
    saleId: 5462,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558372,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150372,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8000000000000000000000'
  },
  {
    saleId: 5463,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558494,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150494,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8000000000000000000000'
  },
  {
    saleId: 5464,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558612,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150612,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8000000000000000000000'
  },
  {
    saleId: 5465,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558755,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150755,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 5466,
    saleType: 0,
    seller: '0xD4b6f06F2817970dcFCE6DC3E9bA6298e9e85Aea',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653558870,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656150870,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8000000000000000000000'
  },
  {
    saleId: 5510,
    saleType: 1,
    seller: '0x67fC6EBe3546d6F49696927F3dCa3BE0b88964E3',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653608047,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656200047,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 5539,
    saleType: 1,
    seller: '0xF79E4cc7A5Dedc344F711f7769cd10a6Bb1d0DE1',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653748498,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656340498,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2800000000000000000000'
  },
  {
    saleId: 5600,
    saleType: 1,
    seller: '0x0DB70e90a4519194f4F6fc02d8577B36c5A5E47B',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1653885863,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656477863,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '250000000000000000000'
  },
  {
    saleId: 5636,
    saleType: 1,
    seller: '0xB357CF48cFF786eFE7b85D61feE760EaE17D8F5B',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654017448,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656609448,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5656,
    saleType: 1,
    seller: '0xd666115C3D251BecE7896297BF446eA908cAF035',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654075803,
    duration: 7776000,
    extensionDuration: 900,
    endTime: 1661851803,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4800000000000000000000'
  },
  {
    saleId: 5673,
    saleType: 1,
    seller: '0x3dec42C1644f614b8C6f16C78C04a08207826e37',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654199596,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656791596,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '7500000000000000000000'
  },
  {
    saleId: 5691,
    saleType: 1,
    seller: '0xd1dAb73fC6C62545D4e5c024133A842d0eA4838F',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654327803,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656919803,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5692,
    saleType: 1,
    seller: '0xd1dAb73fC6C62545D4e5c024133A842d0eA4838F',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654327956,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656919956,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5693,
    saleType: 1,
    seller: '0xd1dAb73fC6C62545D4e5c024133A842d0eA4838F',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654328028,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656920028,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5694,
    saleType: 1,
    seller: '0xdeDfa5F6eB3C858aDE3dd008d6503A68DA5629B4',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654328142,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656920142,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5696,
    saleType: 1,
    seller: '0xd1dAb73fC6C62545D4e5c024133A842d0eA4838F',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654329087,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1656921087,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1400000000000000000000'
  },
  {
    saleId: 5731,
    saleType: 1,
    seller: '0x86d47B26cfb3967fF427fFAd4AC028A8Ddcc4529',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654505099,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657097099,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 5782,
    saleType: 1,
    seller: '0xFE3A593fB8a90185A8E31eDC8eb02E7d889D52f0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654677571,
    duration: 345600,
    extensionDuration: 900,
    endTime: 1655023171,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '110000000000000000000'
  },
  {
    saleId: 5783,
    saleType: 1,
    seller: '0xFE3A593fB8a90185A8E31eDC8eb02E7d889D52f0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654677653,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1654936853,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '175000000000000000000'
  },
  {
    saleId: 5813,
    saleType: 1,
    seller: '0x67430abAA14e5fe522C9eE50fF6987912A8C3338',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654847941,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655452741,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5814,
    saleType: 1,
    seller: '0x67430abAA14e5fe522C9eE50fF6987912A8C3338',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654847989,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655452789,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5815,
    saleType: 1,
    seller: '0x67430abAA14e5fe522C9eE50fF6987912A8C3338',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654848046,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655452846,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 5826,
    saleType: 1,
    seller: '0x3F9023DC53798fFbd321B296E9480729FB4d870A',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654856200,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655461000,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '159000000000000000000'
  },
  {
    saleId: 5827,
    saleType: 1,
    seller: '0x3F9023DC53798fFbd321B296E9480729FB4d870A',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654856272,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655461072,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '159000000000000000000'
  },
  {
    saleId: 5828,
    saleType: 1,
    seller: '0x3F9023DC53798fFbd321B296E9480729FB4d870A',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654856452,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655461252,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '159000000000000000000'
  },
  {
    saleId: 5875,
    saleType: 1,
    seller: '0x067ED9370FcA0D612312bd9741379849470DC927',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654876507,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655481307,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '11600000000000000000000'
  },
  {
    saleId: 5914,
    saleType: 1,
    seller: '0x8DBc169a6fb0FE34149a4BA21E51DC31d1f677EF',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1654971073,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657563073,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1800000000000000000000'
  },
  {
    saleId: 5981,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655126884,
    duration: 259200,
    extensionDuration: 900,
    endTime: 1655386084,
    bidder: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    bidAmount: '1500000000000000000000'
  },
  {
    saleId: 5984,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655127373,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657719373,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5986,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655127572,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657719572,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5988,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655127671,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657719671,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5989,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655127759,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657719759,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5990,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655127841,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657719841,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5991,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655127935,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657719935,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5992,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655128044,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657720044,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5993,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655128124,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657720124,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5994,
    saleType: 1,
    seller: '0x7F0C7Dea51B03Cc9aaDC4D2FC52697b7F0dfa558',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655128245,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657720245,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 5995,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655131585,
    duration: 86400,
    extensionDuration: 900,
    endTime: 1655217985,
    bidder: '0xbD192A189EF64dEA328403873347608Ed594DDC2',
    bidAmount: '1500000000000000000000'
  },
  {
    saleId: 5996,
    saleType: 1,
    seller: '0x1A2b734125d61AcB6d29c44574b2f43076FC99a5',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655135526,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657727526,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9400000000000000000000'
  },
  {
    saleId: 6012,
    saleType: 1,
    seller: '0x4baBae7d0b230d459669CAd7BF397F6b35549252',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655166453,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1655771253,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '300000000000000000000'
  },
  {
    saleId: 6013,
    saleType: 1,
    seller: '0x1A2b734125d61AcB6d29c44574b2f43076FC99a5',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655182214,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1657774214,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '6050000000000000000000'
  },
  {
    saleId: 6085,
    saleType: 1,
    seller: '0xb760eC3c60EFB1735F715BF82afd3b24CA8B4cDe',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655419029,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656023829,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 6086,
    saleType: 1,
    seller: '0xb760eC3c60EFB1735F715BF82afd3b24CA8B4cDe',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655419139,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656023939,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 6087,
    saleType: 1,
    seller: '0xb760eC3c60EFB1735F715BF82afd3b24CA8B4cDe',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655419254,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656024054,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '10000000000000000000000'
  },
  {
    saleId: 6093,
    saleType: 1,
    seller: '0x0EAcf6718Cda374b632519F29dBd4C1309731A04',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655447581,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656052381,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '500000000000000000000'
  },
  {
    saleId: 6123,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655652809,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656257609,
    bidder: '0xd8D930B7E563189e0A6A9EA259Ce8a11109EF133',
    bidAmount: '90000000000000000000'
  },
  {
    saleId: 6179,
    saleType: 1,
    seller: '0x268Db78Eac1940edD6D9D842B0465C1c8E023214',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1655931690,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656536490,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1050000000000000000000'
  },
  {
    saleId: 6193,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656010020,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1658602020,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 6215,
    saleType: 1,
    seller: '0xB035948E4F3a4e89FF565A5d7b592db57bfCC05c',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656067336,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1656672136,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2250000000000000000000'
  },
  {
    saleId: 6274,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656199940,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1658791940,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '249000000000000000000'
  },
  {
    saleId: 6275,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656200167,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1658792167,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '249000000000000000000'
  },
  {
    saleId: 6343,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656373640,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1658965640,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '140000000000000000000'
  },
  {
    saleId: 6347,
    saleType: 1,
    seller: '0xC9F1C0206e852eC32239d70404979B26E0CCF091',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656404059,
    duration: 1209600,
    extensionDuration: 900,
    endTime: 1657613659,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '1390000000000000000000'
  },
  {
    saleId: 6372,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656473610,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659065610,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9900000000000000000000'
  },
  {
    saleId: 6373,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656476845,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659068845,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '130000000000000000000'
  },
  {
    saleId: 6374,
    saleType: 1,
    seller: '0x581F900Fb75D4c37416F1D3AD86b815B4D07A884',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656479448,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659071448,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '11111000000000000000000'
  },
  {
    saleId: 6405,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656653994,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659245994,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9400000000000000000000'
  },
  {
    saleId: 6424,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656767987,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659359987,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '17900000000000000000000'
  },
  {
    saleId: 6425,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656768196,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659360196,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '17900000000000000000000'
  },
  {
    saleId: 6426,
    saleType: 1,
    seller: '0x7eA836A3aa958a69131D363f72Da6660119CA032',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656768358,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657373158,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '8000000000000000000000'
  },
  {
    saleId: 6427,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656768505,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659360505,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '17900000000000000000000'
  },
  {
    saleId: 6434,
    saleType: 1,
    seller: '0x69456b0A15D305983c32d8deA8E56C05d5854A56',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656784231,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659376231,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '17500000000000000000000'
  },
  {
    saleId: 6441,
    saleType: 1,
    seller: '0xae68405fE38349dd8Db2A6F5e7Dc4853559d8fd0',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656801337,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1659393337,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '14900000000000000000000'
  },
  {
    saleId: 6443,
    saleType: 1,
    seller: '0xb59965147dA87119cBDCFDee1d04304381cC6Ec5',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656825123,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657429923,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '30000000000000000000000'
  },
  {
    saleId: 6452,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656846881,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657451681,
    bidder: '0x5C21f79d9fb7a3F4Ef028a20eA079170d1601396',
    bidAmount: '1000000000000000000'
  },
  {
    saleId: 6454,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656875758,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657480558,
    bidder: '0x69726E03d7D427D45715A7b2439cCB97411c9db4',
    bidAmount: '2000000000000000000'
  },
  {
    saleId: 6460,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656923594,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657528394,
    bidder: '0xA573c2f3210d4654DA17d0562f0eD2aF42d37fDB',
    bidAmount: '3000000000000000000'
  },
  {
    saleId: 6461,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656953910,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657558710,
    bidder: '0x833A4A119C1195B4aC1c7F1471152dE8B65a3EF5',
    bidAmount: '69000000000000000000'
  },
  {
    saleId: 6467,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1656976145,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657580945,
    bidder: '0xbD192A189EF64dEA328403873347608Ed594DDC2',
    bidAmount: '70000000000000000000'
  },
  {
    saleId: 6472,
    saleType: 2,
    seller: '0x0000000000000000000000000000000000000000',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657128721,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657733521,
    bidder: '0x0E6e8e8f54E3497E9b146e4F9D9Bec16f89A7aeE',
    bidAmount: '150000000000000000000'
  },
  {
    saleId: 6477,
    saleType: 1,
    seller: '0x34bA4c3769703Ee50f104bE89D719327Ee5e38e8',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657189196,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657793996,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '100000000000000000000'
  },
  {
    saleId: 6486,
    saleType: 1,
    seller: '0x2beDE949b1af2d8200Ab6Abf77a47cAaC57966eC',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657344204,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657949004,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '25000000000000000000000'
  },
  {
    saleId: 6487,
    saleType: 1,
    seller: '0x2beDE949b1af2d8200Ab6Abf77a47cAaC57966eC',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657344303,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1657949103,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '30000000000000000000000'
  },
  {
    saleId: 6492,
    saleType: 1,
    seller: '0xd1dAb73fC6C62545D4e5c024133A842d0eA4838F',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657440980,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1660032980,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '200000000000000000000'
  },
  {
    saleId: 6493,
    saleType: 1,
    seller: '0xd1dAb73fC6C62545D4e5c024133A842d0eA4838F',
    contractAddress: '0xdC48cECc5443cC1982926060a89392c7dF1d4892',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657441037,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1660033037,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '200000000000000000000'
  },
  {
    saleId: 6497,
    saleType: 1,
    seller: '0x783ddc06B147AdbD3224EA465E04E63C8761b9a4',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657550456,
    duration: 604800,
    extensionDuration: 900,
    endTime: 1658155256,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '3900000000000000000000'
  },
  {
    saleId: 6499,
    saleType: 1,
    seller: '0x7eA6F7b33EB393b4B40a15F79Ba54f2C521a8B77',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657799790,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1660391790,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4040000000000000000000'
  },
  {
    saleId: 6500,
    saleType: 1,
    seller: '0x6B66F2389799CF2924E8886F50C2C37dc3C773A0',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1657995749,
    duration: 1209600,
    extensionDuration: 900,
    endTime: 1659205349,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '9990000000000000000000'
  },
  {
    saleId: 6503,
    saleType: 1,
    seller: '0x9D2bAa9dE4C91cdbe115d2De98bA133537f4502b',
    contractAddress: '0xF22348e477753Bc6252237333d9a94B1FCe9c1D3',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658291680,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1660883680,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '110000000000000000000'
  },
  {
    saleId: 6506,
    saleType: 1,
    seller: '0x51DD6113f62cEF673Dc3E060ddF6126Eb17EFCcf',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658341144,
    duration: 1209600,
    extensionDuration: 900,
    endTime: 1659550744,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '5500000000000000000000'
  },
  {
    saleId: 6510,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695540,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287540,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6511,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695618,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287618,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6512,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695680,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287680,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6513,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695739,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287739,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6514,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695801,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287801,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6515,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695881,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287881,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6516,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658695941,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661287941,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6517,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658696020,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661288020,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6518,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658696111,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661288111,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6519,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0x41e928EF2F2523518172f4FeDdE59C815398FAaB',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658696172,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661288172,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '4500000000000000000000'
  },
  {
    saleId: 6520,
    saleType: 1,
    seller: '0x0e0404A12731BeAba553663beaA0C3a6fFC71379',
    contractAddress: '0xdC59f32a58Ba536f639ba39C47cE9a12106b232B',
    tokenType: 0,
    bidToken: '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
    startTime: 1658696413,
    duration: 2592000,
    extensionDuration: 900,
    endTime: 1661288413,
    bidder: '0x0000000000000000000000000000000000000000',
    bidAmount: '2500000000000000000000'
  }
]

export default sales;