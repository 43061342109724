import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketState, Sale } from './types';

const initialState: MarketState = {
  sales: []
}

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setMarketSales: (state, action: PayloadAction<Sale[]>) => {
      state.sales = action.payload;
    }
  }
})

export const { setMarketSales } = marketSlice.actions;

export default marketSlice.reducer;
