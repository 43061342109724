import { DEV_TEST_ADDRESS } from '@constants/variables';
import React, { FC, Fragment } from 'react';
import { useAccount } from "wagmi";
import ApprovalUpdater from './ApprovalUpdater';
import NFTUpdater from './NFTUpdater';
import TokenUpdater from './TokenUpdater';
import LootSwapUpdater from './LootSwapUpdater';

const WalletUpdater: FC = () => {
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address
  if (!wallet) {
    return null;
  }

  return (
    <Fragment>
      <TokenUpdater />
      <NFTUpdater />
      <ApprovalUpdater />
      <LootSwapUpdater />
    </Fragment>
  );
}

export default WalletUpdater;
