import GetLogger from '@components/Logger';
import Addresses from '@constants/addresses';
import { DEV_TEST_ADDRESS, MIN_CACHE_TIME_MS, OLD_CHAIN } from '@constants/variables';
import { useAppDispatch, useAppSelector } from '@state/hooks';
import { selectProviderNetworkOld } from '@state/provider/hooks';
import { selectWalletRefetch, selectWalletNfts, selectWalletBalances } from '@state/wallet/hooks';
import { setWalletNftBalance, setWalletStaked } from '@state/wallet/reducer';
import { GetProfessionStakingContract, GetQueryHelperContract } from '@utils/contracts';
import { FC, useEffect } from 'react';
import { useAccount } from "wagmi";

const NFTUpdater: FC = (): null => {
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address
  const log = GetLogger("NFT Updater");
  const balances = useAppSelector(selectWalletBalances);
  const nfts = useAppSelector(selectWalletNfts)
  const provider = useAppSelector(selectProviderNetworkOld);
  const refetch = useAppSelector(selectWalletRefetch);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!wallet) {
      return;
    }

    const get = async () => {
      const now = Date.now();

      const [stakedNfts] = await provider.all([GetProfessionStakingContract().getStakedOf(wallet)])
      dispatch(setWalletStaked(stakedNfts.map(s => s.tokenId.toNumber())))

      const nftEntries = Object.entries(nfts).filter(([token, ids]) =>
        balances[token].balance.gt(0) && !balances[token].balance.eq(ids.length)
      )
      if (Object.keys(nftEntries).length === 0) {
        return;
      }
      nftEntries.map(async ([token]) => {
        try {
          const [nftIds] = await provider.all([GetQueryHelperContract().tokensOfOwner(Addresses.NFTs[token][OLD_CHAIN], wallet)]);
          const payload = { refetch: now + MIN_CACHE_TIME_MS, nfts: nftIds.map(n => n.toNumber()), token }
          dispatch(setWalletNftBalance(payload))

        } catch (e) {
          log.error("Error querying NFT ID data", e)
          return;
        }
      })
    }
    get();
    const interval = setInterval(() => {
      get();
    }, 60000);

    return () => {
      clearInterval(interval);
    }
  }, [nfts, balances, wallet, refetch]); // eslint-disable-line react-hooks/exhaustive-deps
  return null
}

export default NFTUpdater;
