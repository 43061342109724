import { networkProviders } from "@constants/chains";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProviderState } from '@state/provider/types';

const initialState: ProviderState = {
  networkProviders: networkProviders,
  chainId: 0,
  switching: false
}

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    setProviderChainId: (state, action: PayloadAction<number>) => {
      state.chainId = action.payload
    },
    setProviderSwitching: (state, action: PayloadAction<boolean>) => {
      state.switching = action.payload
    }
  }
})

export const {
  setProviderChainId,
  setProviderSwitching,
} = providerSlice.actions;

export default providerSlice.reducer;