import ERC20 from '@artifacts/ERC20.json';
import COSMIC from '@artifacts/Cosmic.json';
import CElvesTicket from '@artifacts/CosmicElvesTicketUpgradeable.json';
import CWizards3D from '@artifacts/CosmicWizards3D.json';
import LPToken from '@artifacts/LPToken.json';
import Market from '@artifacts/MarketUpgradeable.json';
import MasterLooter from '@artifacts/MasterLooter.json';
import MigrationMike from '@artifacts/MigrationMikeEgressUpgradeable.json';
import ProfessionStaking from '@artifacts/ProfessionStakingUpgradeable.json';
import UniswapV2Router from "@artifacts/UniswapV2Router.json";
import UniswapV2Factory from "@artifacts/UniswapV2Factory.json";
import QueryHelper from "@artifacts/ERC721QueryHelper.json";

const ABIs = {
  ERC20: ERC20.abi,
  ERC721: CWizards3D.abi,
  COSMIC: COSMIC.abi,
  E_TICKET: CElvesTicket.abi,
  WIZARDS3D: CWizards3D.abi,
  LP_TOKEN: LPToken,
  MARKET: Market.abi,
  MASTER_LOOTER: MasterLooter,
  MIGRATION_MIKE: MigrationMike.abi,
  PROFESSION_STAKING: ProfessionStaking.abi,
  UNISWAP_V2_ROUTER: UniswapV2Router.abi,
  UNISWAP_V2_FACTORY: UniswapV2Factory.abi,
  ERC721_QUERY_HELPER: QueryHelper.abi
}

export default ABIs;
