import GetLogger from '@components/Logger';
import Addresses from '@constants/addresses';
import { DEV_TEST_ADDRESS, OLD_CHAIN } from '@constants/variables';
import { useAppDispatch, useAppSelector } from '@state/hooks';
import { selectProviderNetworkOld } from '@state/provider/hooks';
import { selectWalletApprovals, selectWalletEscrow } from '@state/wallet/hooks';
import { setWalletAllApprovals, setWalletEscrow } from '@state/wallet/reducer';
import { GetBridgeContract, GetERC20Contract, GetERC721Contract } from '@utils/contracts';
import { BigNumber, constants } from 'ethers';
import { ContractCall } from 'ethers-multicall';
import { useEffect } from 'react';
import { useAccount } from "wagmi";

const ApprovalUpdater = () : null => {
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address
  const log = GetLogger("Approval Updater");
  const dispatch = useAppDispatch();
  const provider = useAppSelector(selectProviderNetworkOld);
  const approvals = useAppSelector(selectWalletApprovals);
  const escrow = useAppSelector(selectWalletEscrow);
  const relevantOld = ['COSMIC_V1', '2D_Wizards'];
  const relevantMMToken = ['COSMIC', 'MAGIC'];
  const relevantMMNft = ['3D_Wizards', 'Cosmic_Land', 'Framed_Wizards', 'Elves_Tickets'];
  const relevant = relevantOld.concat(relevantMMToken).concat(relevantMMNft);
  useEffect(() => {
    if (!wallet) {
      return;
    }
    const get = async () => {
      const newApprovals = {};

      const approvalCalls: ContractCall[] = [
        GetERC20Contract(Addresses.Tokens.COSMIC_V1[OLD_CHAIN]).allowance(wallet, Addresses.Tokens.COSMIC[OLD_CHAIN]),
        GetERC721Contract(Addresses.NFTs['2D_Wizards'][OLD_CHAIN]).isApprovedForAll(wallet, Addresses.NFTs['3D_Wizards'][OLD_CHAIN]),
      ]
      relevantMMToken.map(t => {
        approvalCalls.push(
          GetERC20Contract(Addresses.Tokens[t][OLD_CHAIN])
            .allowance(wallet, Addresses.NPCs.MIGRATION_MIKE[OLD_CHAIN]),
        )
        return t;
      })
      relevantMMNft.map(t => {
        approvalCalls.push(
          GetERC721Contract(Addresses.NFTs[t][OLD_CHAIN])
            .isApprovedForAll(wallet, Addresses.NPCs.MIGRATION_MIKE[OLD_CHAIN])
        )
        return t;
      })
      try {
        const allApprovals = await provider.all(approvalCalls);
        relevant.map((t, i) => {
          let approved = allApprovals[i];
          if (allApprovals[i] instanceof BigNumber) {
            approved = allApprovals[i].gt(constants.MaxUint256.div(2))
          }
          if (approvals[t] !== approved) {
            newApprovals[t] = approved;
          }
          return t;
        })
      } catch (e) {
        log.error(`Error querying approval data`, e)
      }
      if (Object.keys(newApprovals).length > 0) {
        dispatch(setWalletAllApprovals(newApprovals))
      }
      try {
        const [newEscrow] = await provider.all([GetBridgeContract().lockedEscrow(wallet)])
        if (escrow !== newEscrow) {
          dispatch(setWalletEscrow(newEscrow))
        }
      } catch (e) {
        log.error(`Error querying escrow data`, e)
      }
    }
    get();
    const interval = setInterval(() => {
      get();
    }, 60000);
    return () => {
      clearInterval(interval);
    }
  }, [wallet, approvals]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export default ApprovalUpdater;
