import { Contract as MCContract } from 'ethers-multicall';
import Addresses from '@constants/addresses';
import ABIs from '@constants/abis';
import { Contract, Signer } from "ethers";

import { OLD_CHAIN } from '@constants/variables';

export const GetERC20Contract = (address: string): MCContract => new MCContract(address, ABIs.ERC20);
export const GetERC721Contract = (address: string): MCContract => new MCContract(address, ABIs.ERC721);

export const GetSignedContract = (contract: MCContract, signer: Signer) =>
  new Contract(contract.address, contract.abi, signer);

export const GetLPTokenContract = (address: string): MCContract => new MCContract(address, ABIs.LP_TOKEN);

export const GetCosmicContract = (): MCContract =>
  new MCContract(Addresses.Tokens.COSMIC[OLD_CHAIN], ABIs.COSMIC);

export const GetWizardsContract = (): MCContract =>
  new MCContract(Addresses.NFTs['3D_Wizards'][OLD_CHAIN], ABIs.WIZARDS3D);

export const GetBridgeContract = (): MCContract =>
  new MCContract(Addresses.NPCs.MIGRATION_MIKE[OLD_CHAIN], ABIs.MIGRATION_MIKE);

export const GetUniswapRouterContract = (): MCContract =>
  new MCContract(Addresses.NPCs.LOOT_SWAP_ROUTER[OLD_CHAIN], ABIs.UNISWAP_V2_ROUTER);

export const GetUniswapFactoryContract = (): MCContract =>
  new MCContract(Addresses.NPCs.LOOT_SWAP_FACTORY[OLD_CHAIN], ABIs.UNISWAP_V2_FACTORY);

export const GetUniswapPairContract = (address: string): MCContract =>
  new MCContract(address, ABIs.LP_TOKEN);

export const GetQueryHelperContract = (): MCContract =>
  new MCContract(Addresses.NPCs.ERC721_QUERY_HELPER[OLD_CHAIN], ABIs.ERC721_QUERY_HELPER);

export const GetMarketContract = (): MCContract =>
  new MCContract(Addresses.NPCs.MARKET[OLD_CHAIN], ABIs.MARKET);

export const GetMasterLooterContract = (): MCContract =>
  new MCContract(Addresses.NPCs.MASTER_LOOTER[OLD_CHAIN], ABIs.MASTER_LOOTER);

export const GetProfessionStakingContract = (): MCContract =>
  new MCContract(Addresses.NPCs.PROFESSION_STAKING[OLD_CHAIN], ABIs.PROFESSION_STAKING);

export const GetLPContractName = (address: string): string =>
  Object.entries(Addresses.LPTokens).map(([name, { [OLD_CHAIN]: addr }]) => {
    return address === addr ? name : ''
  }).filter(Boolean)[0]
