import AppBar from '@components/AppBar';
import LoadingBackdrop from '@components/LoadingBackdrop';
import { Box, Grid, useTheme } from '@mui/material';
import WithdrawTrainingCard from '@pages/components/actions/WithdrawTrainingCard';
import UnstakeLPCard from '@pages/components/actions/UnstakeLPCard';
import Concluded from "@pages/Concluded";
import { Slide, ToastContainer } from 'react-toastify';
import BreakLPCard from '@pages/components/actions/BreakLPCard';
import BridgeAssetsCard from '@pages/components/actions/BridgeAssetsCard';
import ConvertAssetsCard from '@pages/components/actions/ConvertAssetsCard';
import DeListAssetsCard from '@pages/components/actions/DelistAssetsCard';
import HeaderCard from '@pages/components/HeaderCard';
import LPBalancesCard from '@pages/components/balances/LPBalancesCard';
import NFTBalancesCard from '@pages/components/balances/NFTBalancesCard';
import TokenBalancesCard from '@pages/components/balances/TokenBalancesCard';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const theme = useTheme();
  return (
    <Box>
      <LoadingBackdrop />
      <AppBar />
      <Concluded />
    </Box>
  )
  return (
    <Box>
      <LoadingBackdrop />
      <AppBar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
        transition={Slide}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 2 }}>
        <Grid container spacing={2} sx={{ width: 0.95, justifyContent: 'space-between', maxWidth: theme.breakpoints.values.lg }}>
          <Grid item xs={12}>
            <HeaderCard title="Balances" />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TokenBalancesCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <LPBalancesCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <NFTBalancesCard />
          </Grid>
          <Grid item xs={12}>
            <HeaderCard title="Actions" />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <UnstakeLPCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BreakLPCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <ConvertAssetsCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <WithdrawTrainingCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <DeListAssetsCard />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <BridgeAssetsCard />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default App;
