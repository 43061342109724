import { OLD_CHAIN } from '@constants/variables';

const Addresses: { [kind: string]: { [token: string]: { [chainId: number]: string } } } = {
  Tokens: {
    COSMIC_V1: {
      [OLD_CHAIN]: "0x6008C8769BFACd92251bA838382e7e5637C7e74D",
    },
    COSMIC: {
      [OLD_CHAIN]: "0x114B697750CE6efd7D935F019d2460984Bc76942",
    },
    MAGIC: {
      [OLD_CHAIN]: "0x892d81221484f690c0a97d3dd18b9144a3ecdfb7",
    },
    ONE: {
      [OLD_CHAIN]: "0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a"
    },
    LOOT: {
      [OLD_CHAIN]: "0xbDa99C8695986B45a0dD3979cC6f3974D9753D30"
    },
    bscBUSD: {
      [OLD_CHAIN]: "0x0aB43550A6915F9f67d0c454C2E90385E6497EaA"
    },
    '1WBTC': {
      [OLD_CHAIN]: "0x3095c7557bCb296ccc6e363DE01b760bA031F2d9"
    },
    bscBTCB: {
      [OLD_CHAIN]: "0x34224dCF981dA7488FdD01c7fdd64E74Cd55DcF7"
    },
    '1ETH': {
      [OLD_CHAIN]: "0x6983D1E6DEf3690C4d616b13597A09e6193EA013"
    },
    bscETH: {
      [OLD_CHAIN]: "0x783EE3E955832a3D52CA4050c4C251731c156020"
    },
    BUSD: {
      [OLD_CHAIN]: "0xE176EBE47d621b984a73036B9DA5d834411ef734"
    }
  },
  LPTokens: {
    COSMIC_MAGIC: {
      [OLD_CHAIN]: "0x5b40F3f160e0804c5353a0b516e65De5e421f493",
    },
    MAGIC_ONE: {
      [OLD_CHAIN]: "0x7629a8782192a5b87eFd40d2eEf366E865A984ae",
    },
    COSMIC_ONE: {
      [OLD_CHAIN]: "0x98c2B8f03f8200FE5EaEBB8FF4CCe6718c8a4134",
    },
    MAGIC_LOOT: {
      [OLD_CHAIN]: "0x86097830F2379300D77F047365CD11D95498cc70",
    },
    MAGIC_bscBUSD: {
      [OLD_CHAIN]: "0x1014A04edd25e213598e653a3B7b658B229D3864",
    },
    '1WBTC_bscBTCB': {
      [OLD_CHAIN]: "0x28C9AaD81cfd38Ca0f332fed9eAA1Ea2628d8A9B",
    },
    '1ETH_bscETH': {
      [OLD_CHAIN]: "0xAeBB1a861194fe6aD90B6ff24dF98E204A968984",
    },
    bscETH_ONE: {
      [OLD_CHAIN]: "0xe30B387D41077A2DFebEf0a2dF0df55C755BB7f7",
    },
    ONE_BUSD: {
      [OLD_CHAIN]: "0xaa781Dc33e968A58640b27Fcd0a536A65AaEFF71",
    },
  },
  VestingTokens: {},
  NFTs: {
    'Cosmic_Land': {
      [OLD_CHAIN]: "0x41e928EF2F2523518172f4FeDdE59C815398FAaB",
    },
    Framed_Wizards: {
      [OLD_CHAIN]: "0xf22348e477753bc6252237333d9a94b1fce9c1d3"
    },
    '3D_Wizards': {
      [OLD_CHAIN]: "0xdC59f32a58Ba536f639ba39C47cE9a12106b232B"
    },
    '2D_Wizards': {
      [OLD_CHAIN]: "0x37f47C343bfAF27A52bC1BD468b49d8E5eF89D67"
    },
    Elves_Tickets: {
      [OLD_CHAIN]: "0xdC48cECc5443cC1982926060a89392c7dF1d4892"
    }
  },
  NPCs: {
    MARKET: {
      [OLD_CHAIN]: "0xC8cEdDf1b1592B3ef5646aF733F14d8fF51a2656",
    },
    PROFESSION_STAKING: {
      [OLD_CHAIN]: "0x8Dfc57c1c1032e08f3DF6C71A6d8b8EF88a7aC21",
    },
    ASSET_CUSTOMIZATION: {
      [OLD_CHAIN]: "0x32614E7D3C52Cd66BF2cb8CC17884749E4B070d4",
    },
    MIGRATION_MIKE: {
      [OLD_CHAIN]: "0xba9564732102222bdbf54b931D783AFCe52053C5",
    },
    GAME_STORAGE: {
      [OLD_CHAIN]: "0xdc0B143afD1c806d142617f99c5eE037Df4bA28f",
    },
    MASTER_LOOTER: {
      [OLD_CHAIN]: "0x12dc61f46718dd00e993da7865da15e8cb713094",
    },
    LOOT_SWAP_ROUTER: {
      [OLD_CHAIN]: "0x6d9eF21E7b93CF0C45847d586E1b9eFCaaB76009"
    },
    LOOT_SWAP_FACTORY: {
      [OLD_CHAIN]: "0x021AeF70c404aa9d70b71C615F17aB3a4038851A"
    },
    ERC721_QUERY_HELPER: {
      [OLD_CHAIN]: "0xd26599F823494a1De265e94cf9DC0Af49fA08DA1"
    }
  },
}

export default Addresses;
