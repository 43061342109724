import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app/reducer';
import marketReducer from './market/reducer';
import providerReducer from './provider/reducer';
import walletReducer from './wallet/reducer';

const store = configureStore({
  reducer: {
    app: appReducer,
    provider: providerReducer,
    wallet: walletReducer,
    market: marketReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
