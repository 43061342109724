import { OLD_CHAIN } from "@constants/variables";
import { configureChains, createClient } from "wagmi";

import type { Chain } from 'wagmi';
import { ReactNode } from "react";
import { providers } from 'ethers';
import { Provider, setMulticallAddress } from 'ethers-multicall';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { LedgerConnector } from 'wagmi/connectors/ledger';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { getDefaultClient } from 'connectkit';

type NativeCurrency = {
  decimals: number,
  name: string,
  symbol: string,
  logo: ReactNode
}

export type EChain = Chain & {
  logo: ReactNode,
  nativeCurrency: NativeCurrency
}

const harmony: EChain = {
  id: 1666600000,
  name: "Harmony",
  logo: "https://cryptologos.cc/logos/harmony-one-logo.png",
  network: "harmony",
  nativeCurrency: {
    decimals: 18,
    name: "Harmony",
    symbol: "HMY",
    logo: "https://cryptologos.cc/logos/harmony-one-logo.png",
  },
  rpcUrls: {
    default: { http: ["https://api.s0.t.hmny.io"] },
  },
  blockExplorers: {
    default: { name: "BlockExplorer", url: "https://explorer.harmony.one/" },
  },
  testnet: false,
  // ens: {
  //  address: '0x4832D668C2c75Fa10C597FD19B116d2E1873ED69'
  //},
  //multicall: {
  //  address: '0x7f3aC7C283d7E6662D886F494f7bc6F1993cDacf',
  //  blockCreated: 9772745
  //}
};

const Chains = { harmony }
export const ChainList = Object.values(Chains);

export const networkBaseProviders = Object.values(Chains).reduce((o, chain) => {

  o[chain.id] = new providers.StaticJsonRpcProvider(chain.rpcUrls.default.http[0])
  return o;
}, {} as { [chainId: number]: providers.StaticJsonRpcProvider})

export const networkProviders: { [p: number]: Provider } = Object.values(Chains).reduce((o, chain) => {
  setMulticallAddress(OLD_CHAIN, "0x34b415f4d3b332515e66f70595ace1dcf36254c5");
  o[chain.id] = new Provider(new providers.JsonRpcProvider(chain.rpcUrls.default.http[0]), chain.id)
  return o;
}, {} as { [chainId: number]: Provider })

const { chains, provider } = configureChains(Object.values(Chains), [
  jsonRpcProvider({
    rpc: (chain) => {
      if (!Object.values(Chains).map(c => c.id).includes(chain.id)) return null;
      return { http: chain.rpcUrls.default.http[0] };
    },
    weight: 1,
  }),
  publicProvider({ weight: 2 }),
])

const appName = "Cosmic Universe Migration Tool";

export const client = createClient(getDefaultClient({
  autoConnect: true,
  appName,
  chains,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Browser Wallet',
        shimDisconnect: true,
      },
    }),
    new MetaMaskConnector({ chains }),
    new LedgerConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName,
        headlessMode: true,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: false,
      },
    }),
  ],
  provider,
}));

export default Chains;