import GetLogger from '@components/Logger';
import { DEV_TEST_ADDRESS } from '@constants/variables';
import { selectProviderNetworkOld } from '@state/provider/hooks';
import { useAppDispatch, useAppSelector } from '@state/hooks';
import { selectWalletPools } from '@state/wallet/hooks';
import { setWalletPools } from '@state/wallet/reducer';
import { GetLPContractName, GetMasterLooterContract } from '@utils/contracts';
import { FC, useEffect, useState } from 'react'
import { useAccount } from "wagmi";

const LootSwapUpdater: FC = (): null => {
  const { address } = useAccount();
  const wallet = DEV_TEST_ADDRESS || address
  const log = GetLogger("LootSwap Updater");
  const dispatch = useAppDispatch();
  const pools = useAppSelector(selectWalletPools);
  const provider = useAppSelector(selectProviderNetworkOld);
  const [poolIds, setPoolIds] = useState<number[]>([]);
  // Get Pool IDs First
  useEffect(() => {
    let isMounted = true;
    if (!wallet || poolIds.length !== 0) {
      return;
    }
    const get = async () => {
      const contract = GetMasterLooterContract()
      const [poolLength] = await provider.all([contract.poolLength()])
      const pool_ids = Array.from(Array(poolLength.toNumber()).keys())
      if (pool_ids !== poolIds && isMounted) {
        setPoolIds(pool_ids)
      }
    }
    get()
      .catch((e) => console.error("Error getting Initial Investor info", provider, e))

    return () => { isMounted = false };
  }, [poolIds, wallet]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!wallet) {
      return;
    }
    const getPoolData = async () => {
      const contract = GetMasterLooterContract();
      const poolInfoCalls = poolIds.map(i => contract.poolInfo(i))
      const poolInfos = await provider.all(poolInfoCalls)
      log.debug(poolInfos)
      const balanceCalls = poolInfos.map((p, i) => contract.userInfo(i, wallet))
      const balances = await provider.all(balanceCalls)
      const newBalances = poolInfos.reduce((o, p, i) => {
        const name = GetLPContractName(p.lpToken)
        if (balances[i].amount.gt(0) && !pools[name].eq(balances[i].amount)) {
          o[name] = balances[i].amount
        }
        return o;
      }, {})
      if (Object.keys(newBalances).length > 0) {
        dispatch(setWalletPools(newBalances))
      }
    };

    getPoolData()
      .catch((e) => log.error("Error getting pool data", e))
    const interval = setInterval(() => {
      getPoolData()
        .catch((e) => log.error("Error getting pool data", e))
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [wallet, poolIds, pools, provider]); // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default LootSwapUpdater;
